export const defaultLivenessActionMessages = {
    KEEP_STILL: 'Quedese quieto',
    BLINK: 'Pestanee',
    ROTATE_YAW: 'Gire la cara hacia el objetivo',
    KEEP_ROTATING_YAW: 'Continúe girando la cara',
    TURN_TO_CENTER: 'Gire la cara al centro',
    TURN_LEFT: 'Gire la cara a la izquierda',
    TURN_RIGHT: 'Gire la cara a la derecha',
    TURN_UP: 'Mire hacia arriba',
    TURN_DOWN: 'Mire hacia abajo',
    MOVE_CLOSER: 'Acerquese a la cámara',
    MOVE_BACK: 'Aléjese de la cámara',
    // INTERNAL
    RETRY: 'Espere...',
    LOADING: 'Cargando...',
    INIT: 'Centre su rostro en el óvalo'
};
