const connectionError = require("./../../images/material/connectionError.svg");
const cameraError = require("./../../images/material/cameraError.svg");
const info1 = require("./../../images/material/info1.svg");
const info2 = require("./../../images/material/info2.svg");
const manyobjects = require("./../../images/material/manyobjects.svg");
const oclusion = require("./../../images/material/oclusion.svg");
const badpose = require("./../../images/material/badpose.svg");
export const ConectionError = `
    ${connectionError}
    <h3>Hubo un problema de conexión</h3>
    <p>Asegúrate de tener una conexión estable a internet.</p>
    <p>Si el problema persiste reintenta nuevamente mas tarde.</p>
`;
export const CameraError = `
    ${cameraError}
    <h3>No pudimos activar tu cámara</h3>
    <p>Asegúrate de otorgar permisos para utilizar la cámara de tu dispositivo.</p>
    <p>Si el problema persiste es probable que otra aplicación este haciendo uso de tu cámara.</p>
`;
export const BadResolution = `
    ${cameraError}
    <h3>Baja resolución de la cámara</h3>
    <p>La resolución de tu cámara es insuficiente para realizar la verificación.</p>
    <p>Por favor, intenta utilizar otro dispositivo con una mejor calidad de imagen.</p>
`;
export const ImageQualityError = `
    ${info1}
    <h3>Mala calidad de video</h3>
    <p>Asegúrate de estar en una zona bien iluminada y vuelve a intentar</p>
    <p>Recuerda centrar tu rostro en el óvalo.</p>
`;
export const VerificationError = `
    ${info2}
    <h3>Error de verificación</h3>
    <p>Hubo un problema interno al detectar tu rostro.</p>
    <p>Vuelva a intentar.</p>
`;
export const ManyObjects = `
    ${manyobjects}
    <h3>Demasiados rostros detectados</h3>
    <p>Asegúrate de estar solo en la cámara y que tu rostro esté centrado dentro del óvalo.</p>
    <p>Luego vuelva a intentar la verificación.</p>
`;
export const Oclusion = `
    ${oclusion}
    <h3>Rostro no detectado</h3>
    <p>Asegúrate de que tu rostro esté completamente visible, libre de obstrucciones, y centrado dentro del óvalo.</p>
    <p>Luego vuelva a intentar la verificación.</p>
`;
export const BadPose = `
    ${badpose}
    <h3>Posición incorrecta</h3>
    <p>Asegúrate de que tu rostro esté centrado y mirando de frente hacia la cámara, dentro del óvalo.</p>
    <p>Luego vuelva a intentar la verificación.</p>
`;
export const tip1 = `
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M480-80q-26 0-47-12.5T400-126q-33 0-56.5-23.5T320-206v-142q-59-39-94.5-103T190-590q0-121 84.5-205.5T480-880q121 0 205.5 84.5T770-590q0 77-35.5 140T640-348v142q0 33-23.5 56.5T560-126q-12 21-33 33.5T480-80Zm-80-126h160v-36H400v36Zm0-76h160v-38H400v38Zm-8-118h58v-108l-88-88 42-42 76 76 76-76 42 42-88 88v108h58q54-26 88-76.5T690-590q0-88-61-149t-149-61q-88 0-149 61t-61 149q0 63 34 113.5t88 76.5Zm88-162Zm0-38Z"/></svg>
    <p>Ubíquese en un sitio con buena iluminación.</p>
`;
export const tip2 = `
    <svg xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.9); transform-origin: center;" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M480-120q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM254-346l-84-86q59-59 138.5-93.5T480-560q92 0 171.5 35T790-430l-84 84q-44-44-102-69t-124-25q-66 0-124 25t-102 69ZM84-516 0-600q92-94 215-147t265-53q142 0 265 53t215 147l-84 84q-77-77-178.5-120.5T480-680q-116 0-217.5 43.5T84-516Z"/></svg>
    <p>Asegúrese de estar conectado a internet.</p>
`;
export const tip3 = `
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q134 0 227 93t93 227q0 134-93 227t-227 93Zm0-80q100 0 170-70t70-170q0-17-2.5-33.5T710-546q-15 3-30 4.5t-30 1.5q-63 0-120-24t-102-70q-28 57-77 99t-111 61q3 98 72.5 166T480-240ZM256-566q44-23 67-53.5t45-72.5q-38 20-67 52.5T256-566Zm124 166q-17 0-28.5-11.5T340-440q0-17 11.5-28.5T380-480q17 0 28.5 11.5T420-440q0 17-11.5 28.5T380-400Zm270-220h12q6 0 12-1-33-45-83.5-72T480-720h-12q-6 0-11 1 39 45 82.5 72T650-620Zm-70 220q-17 0-28.5-11.5T540-440q0-17 11.5-28.5T580-480q17 0 28.5 11.5T620-440q0 17-11.5 28.5T580-400ZM40-720v-120q0-33 23.5-56.5T120-920h120v80H120v120H40ZM240-40H120q-33 0-56.5-23.5T40-120v-120h80v120h120v80Zm480 0v-80h120v-120h80v120q0 33-23.5 56.5T840-40H720Zm120-680v-120H720v-80h120q33 0 56.5 23.5T920-840v120h-80Zm-383 1Zm-89 27Z"/></svg>
    <p>Ubique la cámara del dispositivo a la altura de su rostro.</p>
`;
