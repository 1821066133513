import { LivenessAction } from "../../models/enums/LivenessAction";
import { setSvgSize } from "./setSvgSize";
export const setSvgOvalSize = (svgElement, videoElement, livenessAction = [], sizeRatio = 0.42) => {
    setSvgSize(svgElement, videoElement);
    const ovalMask = svgElement.querySelector('#oval-mask');
    const ovalBkg = svgElement.querySelector('#oval-bkg');
    const width = videoElement.clientWidth;
    const height = videoElement.clientHeight;
    const videoX = videoElement.videoWidth;
    const videoY = videoElement.videoHeight;
    let ratioVideo = videoX / videoY;
    let ratioSVG = width / height;
    let ratio;
    let isWidth;
    if (ratioVideo > ratioSVG) {
        ratio = ratioVideo;
        isWidth = false;
    }
    else {
        ratio = ratioSVG;
        isWidth = true;
    }
    let sizeX = (width / 2) * sizeRatio / ratio;
    if (isWidth) {
        let diff = (sizeX + ((sizeX / 100) * 2)) - ((height * ratioVideo) / 2);
        if (diff > 0) {
            sizeX = sizeX - diff - ((height * ratioVideo) * 0.15);
        }
    }
    else {
        let diff = (sizeX + ((sizeX / 100) * 2)) - ((width) / 2);
        if (diff > 0) {
            sizeX = sizeX - diff - (width * 0.15);
        }
    }
    let sizeYRatio = 1.35;
    let sizeY = sizeX * sizeYRatio;
    let centX = width / 2;
    let centY = height / 2;
    ovalMask.setAttribute('cx', `${centX}px`);
    ovalMask.setAttribute('cy', `${centY}px`);
    ovalMask.setAttribute('rx', `${sizeX}px`);
    ovalMask.setAttribute('ry', `${sizeY}px`);
    ovalBkg.setAttribute('cx', `${centX}px`);
    ovalBkg.setAttribute('cy', `${centY}px`);
    ovalBkg.setAttribute('rx', `${sizeX + 5}px`);
    ovalBkg.setAttribute('ry', `${sizeY + 5}px`);
    if (livenessAction.includes(LivenessAction.KEEP_STILL)) {
        ovalBkg.classList.add('highlight');
    }
    else {
        ovalBkg.classList.remove('highlight');
    }
};
