export const buildOnBoarding = (container, items, title) => {
    const titleElement = document.createElement('h3');
    titleElement.innerText = title;
    container.appendChild(titleElement);
    if (items && items.length) {
        items.forEach(tipContent => {
            const itemElement = document.createElement('div');
            const tipElement = document.createElement('div');
            itemElement.classList.add('item');
            tipElement.classList.add('tip');
            tipElement.innerHTML = tipContent;
            itemElement.appendChild(tipElement);
            container.appendChild(itemElement);
        });
    }
};
