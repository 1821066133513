import { CustomErrors } from "../../models/enums/CustomErrors";
import { IcaoWarnings } from "../../models/enums/IcaoWarnings";
import { LivenessAction } from "../../models/enums/LivenessAction";
import { OperationResultStatus } from "../../models/enums/OperationResultStatus";
import { VideoEncoding } from "../../models/enums/VideoEncoding";
import { defaultCustomErrorsMessage } from "../defaultValues/DefaultCustomErrorsMessage";
import { defaultIcaoWarningMessages } from "../defaultValues/DefaultIcaoWarningMessages";
import { defaultLivenessActionMessages } from "../defaultValues/DefaultLivenessActionMessages";
import { defaultOperationResultMessage } from "../defaultValues/DefaultOperationResultMessage";
import { defaultVideoEncondingConfiguration } from "../defaultValues/DefaultVideoEnconding";
export const getLivenessAction = (action, messages = defaultLivenessActionMessages) => {
    if (Array.isArray(action)) {
        // Obtengo el primer elemento que esta en el Enum
        const enumValue = action.find(x => x in LivenessAction);
        if (!enumValue)
            return '';
        return messages[LivenessAction[enumValue]];
    }
    else if (action in LivenessAction) {
        return messages[LivenessAction[action]];
    }
    return '';
};
export const getIcoWarning = (warning, messages = defaultIcaoWarningMessages) => {
    if (Array.isArray(warning)) {
        // Obtengo el primer elemento que esta en el Enum
        const enumValue = warning.find(x => x in IcaoWarnings);
        if (!enumValue)
            return '';
        return messages[IcaoWarnings[enumValue]];
    }
    else if (warning in IcaoWarnings) {
        return messages[IcaoWarnings[warning]];
    }
    return '';
};
export const getOperationResultStatus = (status, messages = defaultOperationResultMessage) => {
    if (status in OperationResultStatus) {
        return messages[OperationResultStatus[status]];
    }
    return '';
};
export const getCustomError = (customError, messages = defaultCustomErrorsMessage) => {
    if (customError in CustomErrors) {
        return messages[CustomErrors[customError]];
    }
    return '';
};
export const getVideoEnconding = (videoEnconding, configurations = defaultVideoEncondingConfiguration) => {
    if (videoEnconding in VideoEncoding) {
        return configurations[VideoEncoding[videoEnconding]];
    }
    return null;
};
