export class Carousel {
    constructor(carrousel, time = 4000) {
        this.carousel = carrousel;
        this.items = this.carousel.querySelectorAll('.item');
        this.lastIndex = this.items.length - 1;
        this.beforeIndex = this.lastIndex;
        this.currentIndex = 0;
        this.afterIndex = 1;
        this.time = time;
    }
    start() {
        this.animate(false);
        this.setItemsPosition();
        this.interval = setInterval(() => {
            this.animate(true);
            this.beforeIndex = this.currentIndex;
            this.currentIndex = this.afterIndex;
            this.afterIndex = (this.afterIndex + 1) <= this.lastIndex ? (this.afterIndex + 1) : 0;
            this.setItemsPosition();
        }, this.time);
    }
    stop() {
        clearInterval(this.interval);
    }
    goTo(currentIndex = 0) {
        if (currentIndex <= this.lastIndex && currentIndex >= 0) {
            this.stop();
            this.currentIndex = currentIndex;
            this.beforeIndex = currentIndex === 0 ? this.lastIndex : currentIndex - 1;
            this.afterIndex = currentIndex === this.lastIndex ? 0 : currentIndex + 1;
            this.start();
        }
    }
    reset() {
        this.stop();
        this.beforeIndex = this.lastIndex;
        this.currentIndex = 0;
        this.afterIndex = 1;
        this.start();
    }
    setItemsPosition() {
        this.items.forEach(x => x.classList.remove('before', 'current', 'after'));
        const beforeElement = this.items[this.beforeIndex];
        const currentElement = this.items[this.currentIndex];
        const afterElement = this.items[this.afterIndex];
        beforeElement.classList.add('before');
        currentElement.classList.add('current');
        afterElement.classList.add('after');
    }
    animate(on_off = true) {
        if (on_off) {
            this.items.forEach(x => x.classList.add('animate'));
        }
        else {
            this.items.forEach(x => x.classList.remove('animate'));
        }
    }
}
