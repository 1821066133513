import { VideoEncoding } from "../../models/enums/VideoEncoding";
export const defaultFVOServiceConfiguration = {
    basSessionApi: {
        baseUrl: `${location.origin}/api/session`
    },
    serverLogApi: {
        baseUrl: `${location.origin}/api/processLog`
    },
    debug: false,
    log: false,
    file: false,
    videoQuality: {
        width: { ideal: 1920 },
        height: { ideal: 1080 },
        frameRate: { max: 30 }
    },
    encoding: VideoEncoding.Normal,
    fvoSdkParameters: {
        basePath: `${location.origin}/api`,
        username: "user",
        password: "password"
    },
    dataReceptionTimeout: 3500,
    limitConnectionTime: 3000
};
