import { defaultCustomErrorsMessage } from "./DefaultCustomErrorsMessage";
import { defaultIcaoWarningMessages } from "./DefaultIcaoWarningMessages";
import { defaultLivenessActionMessages } from "./DefaultLivenessActionMessages";
import { defaultOperationResultMessage } from "./DefaultOperationResultMessage";
import { tip1, tip2, tip3 } from "./DefaultUserMessages";
export const defaultAppConfiguration = {
    container: null,
    messages: defaultCustomErrorsMessage,
    actionMessages: defaultLivenessActionMessages,
    icaoWarningMessages: defaultIcaoWarningMessages,
    resultStatusMessages: defaultOperationResultMessage,
    tips: {
        title: 'recomendaciones',
        items: [tip1, tip2, tip3],
        carouselTime: 4000
    },
    theme: 'default',
    showDebug: false,
    maskExceptions: true,
    FVOConfiguration: null
};
