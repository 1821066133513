export const defaultVideoEncondingConfiguration = {
    None: null,
    Low: {
        maxWidth: 1280,
        maxHeight: 720,
        maxBitrate: 2000000,
        maxFramerate: 10,
        active: true,
        prioriy: 'medium'
    },
    Normal: {
        maxWidth: 1280,
        maxHeight: 720,
        maxBitrate: 3000000,
        maxFramerate: 15,
        active: true,
        prioriy: 'medium'
    },
    High: {
        maxWidth: 1920,
        maxHeight: 1080,
        maxBitrate: 6000000,
        maxFramerate: 30,
        active: true,
        prioriy: 'medium'
    }
};
